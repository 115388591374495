import React from "react";





function Contact() {
    return (
        <div>
            <h1>Our Contact Info</h1>
        </div>
    );

}


export default Contact;